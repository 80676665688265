
import { defineComponent, reactive, toRefs, ref, Ref, getCurrentInstance, watchEffect, nextTick } from 'vue';
import _ from 'lodash/fp';
import { getTenantDepartment, createDept, delDept, delUser, updateDept } from '@/api/company/dept';
import { getUserList } from '@/api/company/users';
import TreeSelector from './components/TreeSelector.vue';
import { ElMessageBox } from 'element-plus';
import { debounce } from 'lodash';
import PackagedPagination from '@/components/pagination/Index.vue';
import { getShowBool } from '@/utils/permission-show-module';
import { TreeDataType, TableDataType, ResCode, UserStatus } from './depart';
const treeProps = {
  label: 'name',
  children: 'children',
  isLeaf: 'isLeaf',
};
export default defineComponent({
  name: 'Department',
  components: { TreeSelector, PackagedPagination },
  setup() {
    const treeData: TreeDataType = reactive({
      loading: false,
      currentNodeData: {},
      currentNode: {},
      currentNodeUsers: [],
      isSel: false,
      treeDataSource: [],
    });

    const tableData: TableDataType = reactive({
      total: 0,
      searchProps: {
        page: 1,
        pageSize: 10,
        keyword: '',
      },
      tableDataSource: [],
    });
    const queryUserListData: Ref<Array<any>> = ref([]);
    const treeSelectorDept: Ref<any> = ref(null);
    const selectedUser: Ref<Array<any>> = ref([]);
    const userTreeRef: any = ref(null);
    // tree selector数据
    const allDeptUser: Ref<Array<any>> = ref([]);
    const treeSelectorRole: any = ref({});
    const otherRoleUser: Ref<Array<any>> = ref([]);
    // 获取组件实例
    const instance = getCurrentInstance();
    const dialogVisible: Ref<boolean> = ref(false);
    let oldDeptName = '';
    const formData = reactive({
      isEdit: false,
      deptName: '',
    });
    // 编辑的数据
    const editFormData = ref();
    // 所有的人员
    const allUsers = ref([]);

    // 校验规则
    const formRules = {
      deptName: [
        { required: true, message: '请输入部门名称', trigger: 'blur' },
        { min: 2, max: 50, message: '长度在 2 到 50 个字符', trigger: 'blur' },
      ],
    };
    // 提示信息
    function msgTips(type: string, content: string) {
      (instance as any).proxy.$message({
        type,
        message: content,
      });
    }
    // 获取部门数据
    const initDepartments = async () => {
      treeData.loading = true;
      const { code, data } = await getTenantDepartment({ deptId: 0, level: 9 });
      if (code === 0) {
        const deptTree = [
          {
            name: data.tenant.name,
            _children: [],
            id: 0,
            isLeaf: false,
          },
        ];
        const setChildren = (parentNode: any, allData: any) => {
          const childrenUser = _.flow(
            _.filter({ deptId: parentNode.id }),
            _.map((user: any) => ({
              id: user.id,
              name: user.displayName,
              deptName: parentNode.name,
              parent: parentNode,
              isLeaf: true,
            })),
          )(allData.users);

          const childrenDept = _.flow(
            _.filter({ parentId: parentNode.id }),
            _.map((dept: any) => ({
              id: dept.deptId,
              name: dept.deptName,
              parent: parentNode,
              isLeaf: false,
            })),
          )(allData.depts);
          childrenDept.forEach((dept: any) => {
            setChildren(dept, allData);
          });
          // eslint-disable-next-line no-param-reassign
          parentNode._children = [...childrenUser, ...childrenDept];
        };
        setChildren(deptTree[0], data);
        allDeptUser.value = deptTree;
        allUsers.value = data.users.map((user: any) => ({
          ...user,
          status: user.status,
        }));
        treeData.loading = false;
      }
    };

    // 初始化表格数据
    const initTableData = () => {
      tableData.total = 0;
      tableData.searchProps = {
        page: 1,
        pageSize: 10,
        keyword: '',
      };
      treeData.currentNodeData = {};
      tableData.tableDataSource = [];
    };

    initDepartments();

    // 获取当前table列表数据
    const getCurrentTableData = (data: any, page: number, pageSize: number) => {
      tableData.total = data.length;
      const pageMaxCount = page * pageSize;
      const startIndex = (page - 1) * pageSize;
      const endIndex = page * pageSize;
      if (pageMaxCount < tableData.total) {
        tableData.tableDataSource = data.slice(startIndex, endIndex);
      } else {
        tableData.tableDataSource = data.slice(startIndex, pageMaxCount - 1);
      }
    };
    // 更新节点数据
    const updateKeyChildren = (data: any) => {
      userTreeRef.value.updateKeyChildren(data);
    };
    // 移除节点
    const removeNode = (data: any) => {
      userTreeRef.value.remove(data);
    };
    // 添加子节点
    const append = (data: any, parentData: any) => {
      userTreeRef.value.append(data, parentData);
    };
    // 移除table表中的某行数据
    const removeTableRow = (data: any) => {
      // 过滤
      allUsers.value = allUsers.value.filter((item: any) => item.id !== data.id);
      treeData.currentNodeUsers = treeData.currentNodeUsers.filter((item: any) => item.id !== data.id);
      const { page, pageSize } = tableData.searchProps;
      getCurrentTableData(treeData.currentNodeUsers, page, pageSize);
    };

    // 删除人员
    const handleDelPerson = (data: any): void => {
      const currentDept = treeData.currentNodeData._children
        ? treeData.currentNodeData
        : treeData.currentNodeData.parent;
      ElMessageBox.confirm(`是否将【${data.displayName || data.name}】从【${currentDept.name}】删除`, '提示', {
        confirmButtonText: '确定删除',
        cancelButtonText: '取消',
        type: 'warning',
      }).then(async () => {
        // 待传参
        const { code } = await delUser({ userIds: [data.id] });
        if (code === ResCode.Success) {
          removeNode(data);
          removeTableRow(data);
          msgTips('success', '删除成功');
        } else {
          msgTips('error', '删除失败');
        }
      });
    };

    // 删除部门和人员
    const handleDel = () => {
      if (treeData.currentNodeData._children) {
        if (treeData.currentNodeData._children.length) {
          msgTips('warning', '该部门下不为空，无法删除！');
          return;
        }
        // 判断部门中是否有人员
        // 获取当前选中的节点
        ElMessageBox.confirm(`是否删除已选项?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(async () => {
          // 删除部门
          const { id } = treeData.currentNodeData;
          const { code } = await delDept({ id });
          if (code === ResCode.Success) {
            msgTips('success', '删除成功');
            removeNode(treeData.currentNodeData);
            initTableData();
          } else {
            msgTips('error', '删除失败');
          }
        });
      } else {
        // 删除人员
        handleDelPerson(treeData.currentNodeData);
      }
    };

    // 查询租户下所有的用户
    const queryUserList = async () => {
      const { code, data } = await getUserList({
        keyword: '',
        page: 1,
        pageSize: 50000,
      });
      if (code !== ResCode.Success) {
        msgTips('error', '获取人员列表失败');
      } else {
        const { rows } = data;
        queryUserListData.value = rows.map((item: any) => {
          const { id, displayName } = item;
          return {
            ...item,
            name: displayName,
            id,
            isLeaf: true,
          };
        });
      }
    };

    // 添加人员
    const handleAddPerson = (): void => {
      // 获取当前选中部门的节点id
      treeSelectorRole.value = treeData.currentNode;
      treeSelectorDept.value.show();
      queryUserList();
    };

    // 重命名
    const handleRename = (data: any): void => {
      editFormData.value = data;
      formData.isEdit = true;
      oldDeptName = data.name;
      formData.deptName = data.name;
      dialogVisible.value = true;
    };

    // 上移
    const handleUpMove = async (data: any) => {
      const res = data;
      const { id, name } = data;
      const { code } = await updateDept({
        id,
        deptName: name,
        parentId: data.parent.parent.id,
      });
      if (code === ResCode.Success) {
        msgTips('success', '上移成功');
        res.parent._children = data.parent._children.filter((item: any) => item.id !== data.id);
        removeNode(data);
        append({ ...data }, data.parent.parent);
      } else {
        msgTips('error', '上移失败');
      }
    };

    const reloadUserList = (data: any) => {
      // 当前部门下的人员
      const { parentData, users } = data;
      const newUserList = users.filter((item: any) => {
        const oldList = parentData.children ? [...parentData._children, ...parentData.children] : parentData._children;
        return !oldList.find((subItem: any) => {
          const isTrue = subItem.id === item.id;
          return isTrue;
        });
      });
      // 过滤当前组织下已存在的人员
      newUserList.forEach((item: any) => {
        append({ ...item, parent: parentData }, data.parentData);
      });
      treeData.currentNodeUsers = [...treeData.currentNodeUsers, ...newUserList];
      const { page, pageSize } = tableData.searchProps;
      getCurrentTableData(treeData.currentNodeUsers, page, pageSize);
    };

    function nodeClickHandle(data: any, node: any): void {
      // 首节点不做选中
      treeData.isSel = true;
      treeData.currentNodeData = data;
      treeData.currentNode = node;
      const { page, pageSize } = tableData.searchProps;
      if (!data._children) {
        let res: any = allUsers.value.find((item: any) => item.id === data.id);
        res ? allUsers.value.push(data as never) : (res = data);
        getCurrentTableData([res], page, pageSize);
        return;
      }
      if (data.id === 0) {
        // 表格数据清空
        getCurrentTableData([], page, pageSize);
        return;
      }
      const resData = _.intersectionWith((node: any, user: any) => node.id === user.id)(allUsers.value)(
        node.data._children,
      );
      treeData.currentNodeUsers = resData;
      getCurrentTableData(resData, page, pageSize);
    }
    // 初始化添加部门表单数据
    const initFormData = () => {
      formData.isEdit = false;
      formData.deptName = '';
    };

    // 添加子部门
    const handleAddDept = (): void => {
      // 判断当前选中的是不是部门
      if (!treeData.currentNodeData._children) {
        msgTips('warning', '请选中一个部门！');
      } else {
        dialogVisible.value = true;
      }
    };
    // 关闭dialog
    const closeDialog = () => {
      initFormData();
      dialogVisible.value = false;
    };
    const deptDiagFormRef: any = ref(null);
    // 提交添加 & 修改部门
    const submitConfigForm = () => {
      deptDiagFormRef.value.validate(async (valid: boolean) => {
        if (valid) {
          let res;
          if (formData.isEdit) {
            if (oldDeptName === formData.deptName) {
              closeDialog();
              return;
            }
            const { id, parent } = editFormData.value;
            res = await updateDept({
              parentId: parent ? parent.id : 0,
              deptName: formData.deptName,
              id,
            });
          } else {
            const { id } = treeData.currentNodeData;
            res = await createDept({
              deptName: formData.deptName,
              parentId: id,
            });
          }
          const { code, data } = res;
          if (code === ResCode.Success) {
            if (formData.isEdit) {
              editFormData.value.name = formData.deptName;
              updateKeyChildren(editFormData.value);
            } else {
              const newNodeData = {
                id: data.deptId,
                name: formData.deptName,
                _children: [],
                isLeaf: false,
              };
              treeData.currentNodeData._children.push(newNodeData);
              append(newNodeData, treeData.currentNodeData);
            }
            msgTips('success', `${formData.isEdit ? '编辑' : '添加'}成功！`);
            closeDialog();
          } else {
            closeDialog();
            msgTips('error', `${formData.isEdit ? '编辑' : '添加'}失败！`);
          }
        }
      });
    };

    // 加载node节点数据
    const dataDone = ref(true);
    let copyOption: any;
    watchEffect(() => {
      dataDone.value = false;
      selectedUser.value = [];
      copyOption = _.cloneDeep(allDeptUser.value);
      nextTick(() => {
        dataDone.value = true;
      });
    });
    const loadNode = (node: any, resolve: Function) => {
      if (node.level === 0) {
        resolve(copyOption);
      } else {
        resolve(node.data._children);
      }
    };

    // 查询
    const serchUserList = (keyword: string) => {
      const { pageSize, page } = tableData.searchProps;
      // 获取当前的所有人员数据
      const filterRes = treeData.currentNodeUsers.filter((subItem: any) => subItem.displayName.includes(keyword));
      getCurrentTableData(filterRes, page, pageSize);
    };
    const filterAccount = debounce(serchUserList, 500);

    // 页面size改变
    const handlePageSizeChange = (data: any) => {
      const { page } = tableData.searchProps;
      tableData.searchProps.pageSize = data;
      getCurrentTableData(treeData.currentNodeUsers, page, data);
    };
    // 页面改变
    const handlePageChange = (data: any) => {
      const { pageSize } = tableData.searchProps;
      tableData.searchProps.page = data;
      getCurrentTableData(treeData.currentNodeUsers, data, pageSize);
    };

    return {
      ...toRefs(tableData),
      ...toRefs(treeData),
      treeSelectorDept,
      userTreeRef,
      treeSelectorRole,
      selectedUser,
      otherRoleUser,
      dialogVisible,
      formData,
      formRules,
      deptDiagFormRef,
      treeProps,
      dataDone,
      filterAccount,
      queryUserListData,
      handleAddDept,
      handleDelPerson,
      handleDel,
      handleAddPerson,
      handleRename,
      handleUpMove,
      nodeClickHandle,
      reloadUserList,
      closeDialog,
      submitConfigForm,
      loadNode,
      handlePageSizeChange,
      handlePageChange,
      getShowBool,
      UserStatus,
    };
  },
});
